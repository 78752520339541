import React, { ReactNode } from 'react';
import { AuthProvider } from './AuthContext';
import moment from 'moment';
import 'moment/locale/pl';

moment.locale('en-gb', {
  week: {
    dow: 1,
  },
});

type Props = {
  children: ReactNode;
};

function AppProviders({ children }: Props) {
  return <AuthProvider>{children}</AuthProvider>;
}

export default AppProviders;
