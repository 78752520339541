import React, { useState } from 'react';
import QrCode from '../../components/QrCode';
import { Col, Row } from 'antd';
import StockPointerTable from 'views/StockPointers/StockPointerTable';

function StockPointers() {
  const [selectedCode, setSelectedCode] = useState<string>();

  return (
    <Row gutter={16}>
      <Col span={16}>
        <StockPointerTable setSelectedCode={setSelectedCode} />
      </Col>
      <Col span={8}>{selectedCode ? <QrCode text={selectedCode} style={{ width: 160, height: 160 }} /> : null}</Col>
    </Row>
  );
}

export default StockPointers;
