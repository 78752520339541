import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductTag } from 'models/product-tag.model';
import { SelectableProductTag } from './Tags';
import { Button } from 'primereact/button';
import { TableHeader } from 'components/TableHeader';

interface Props {
  items: ProductTag[];
  selectedItem: SelectableProductTag;
  setSelectedItem: (item: SelectableProductTag) => void;
  loading?: boolean;
}

export function TagTableOverview({ items, selectedItem, setSelectedItem, loading = false }: Props) {
  return (
    <div className="h-full">
      <DataTable
        header={<TableHeader onAddClick={() => setSelectedItem({ name: '' } as ProductTag)} />}
        value={items}
        scrollable
        scrollHeight="flex"
        filterDisplay="row"
        selectionMode="single"
        selection={selectedItem}
        onSelectionChange={(e) => setSelectedItem(e.value as ProductTag)}
        loading={loading}
      >
        <Column field="id" header="Id" filter showFilterMenu={false} sortable />
        <Column field="name" header="Name" filter showFilterMenu={false} sortable />
      </DataTable>
    </div>
  );
}
