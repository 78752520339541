import React from 'react';
import ProductForm from './ProductForm';
import { Spin } from 'antd';
import { useParams } from 'react-router';
import { Product } from '../../models/product.model';

type Props = {
  products: Product[];
  editProduct: (product: Product) => void;
  isLoading: boolean;
};

function ProductEdit({ products, editProduct, isLoading }: Props) {
  const { id } = useParams<{ id: string }>();
  const product = products ? products.find((product) => product.id === id) : undefined;

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return <ProductForm processProduct={editProduct} product={product} products={products} />;
}

export default ProductEdit;
