import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Login from './views/Authentication/Login';

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to="/login" exact />
    </Switch>
  );
}

export default UnauthenticatedApp;
