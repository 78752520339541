import React from 'react';
import ProductForm from './ProductForm';
import { Spin } from 'antd';
import { Product } from '../../models/product.model';

type Props = {
  products: Product[];
  addProduct: (product: Product) => void;
  isLoading: boolean;
};

function ProductAdd({ products, addProduct, isLoading }: Props) {
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return <ProductForm processProduct={addProduct} products={products} />;
}

export default ProductAdd;
