import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Radio } from 'antd';
import { stringSorter } from '../../utils/sorters';
import TranslationsApi from 'api/translationsApi';
import { SaveOutlined, CloseOutlined, ExportOutlined } from '@ant-design/icons';
import { Language } from '../../models/language.model';
import { TranslationValue } from '../../models/translation-value.model';
import { RadioChangeEvent } from 'antd/es/radio';

type ValueItemProps = {
  text: string;
  record: TranslationValue;
  language: Language;
  editTranslation: (record: TranslationValue, textValue: string) => void;
};

function ValueItem({ text, record, language, editTranslation }: ValueItemProps) {
  const [textValue, setTextValue] = useState(text);

  const setTranslation = () => {
    const model = {
      languageId: language.id,
      translationId: record.translationId,
      value: textValue,
    };
    TranslationsApi.setTranslation(model).then(() => editTranslation(record, textValue));
  };

  return (
    <div style={{ display: 'flex' }}>
      <Input value={textValue} onChange={(e) => setTextValue(e.target.value)} />
      <Button type="primary" shape="round" htmlType="submit" disabled={textValue === text} onClick={setTranslation}>
        <SaveOutlined />
      </Button>
    </div>
  );
}

type Props = {
  selectedLanguage: Language;
  setSelectedLanguage: (language?: Language) => void;
};

function TranslationTable({ selectedLanguage, setSelectedLanguage }: Props) {
  const [translations, setTranslations] = useState<TranslationValue[]>([]);
  const [translationsForDisplay, setTranslationsForDisplay] = useState<TranslationValue[]>([]);
  const [filterByTranslationType, setFilterByTranslationType] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const editTranslation = (record: TranslationValue, textValue: string) => {
    setTranslations((prevTranslation) =>
      prevTranslation.map((x) => {
        if (x.translationId === record.translationId) {
          return { ...x, previousValue: record.value, value: textValue };
        }
        return x;
      }),
    );
    setTranslationsForDisplay((prevTranslation) =>
      prevTranslation.map((x) => {
        if (x.translationId === record.translationId) {
          return { ...x, previousValue: record.value, value: textValue };
        }
        return x;
      }),
    );
  };

  const deactivateLanguage = () => {
    TranslationsApi.unselectLanguage(selectedLanguage.id).then(() => {
      setSelectedLanguage(undefined);
    });
  };

  const publishLanguage = () => {
    TranslationsApi.publishLanguage(selectedLanguage.id).then(() => {
      setSelectedLanguage({ ...selectedLanguage, isPublished: true });
    });
  };
  const unpublishLanguage = () => {
    TranslationsApi.unpublishLanguage(selectedLanguage.id).then(() => {
      setSelectedLanguage({ ...selectedLanguage, isPublished: false });
    });
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: '25%',
      sorter: (a: TranslationValue, b: TranslationValue) => stringSorter(a.key, b.key),
    },
    {
      title: 'English',
      key: 'englishValue',
      dataIndex: 'englishValue',
      width: '25%',
      sorter: (a: TranslationValue, b: TranslationValue) => stringSorter(a.englishValue ?? '', b.englishValue ?? ''),
    },
    {
      title: 'Previous Value',
      key: 'previousValue',
      dataIndex: 'previousValue',
      width: '25%',
      sorter: (a: TranslationValue, b: TranslationValue) => stringSorter(a.previousValue ?? '', b.previousValue ?? ''),
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      width: '25%',
      sorter: (a: TranslationValue, b: TranslationValue) => stringSorter(a.value ?? '', b.value ?? ''),
      render: (text: string, record: TranslationValue) => (
        <ValueItem text={text} record={record} language={selectedLanguage} editTranslation={editTranslation} />
      ),
    },
  ];

  const filterTranslation = (event: RadioChangeEvent) => {
    setFilterByTranslationType(event.target.value);
    setTranslationsForDisplay(translations.filter((x) => x.type === event.target.value));
  };

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setTranslations([]);
    setTranslationsForDisplay([]);
    TranslationsApi.getTranslationsForLanguageId(selectedLanguage.id)
      .then((response) => {
        setTranslations(response.data);
        setTranslationsForDisplay(response.data.filter((x) => x.type === filterByTranslationType));
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [filterByTranslationType, selectedLanguage]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div style={{ marginLeft: '10px', width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 style={{ margin: '5px' }}>{selectedLanguage.name}</h3>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            onClick={deactivateLanguage}
            disabled={selectedLanguage.isPublished}
            style={{ marginRight: '5px' }}
          >
            <CloseOutlined />
            Deactivate
          </Button>
          {!selectedLanguage.isPublished ? (
            <Button type="primary" shape="round" htmlType="submit" onClick={publishLanguage}>
              <ExportOutlined />
              Publish
            </Button>
          ) : null}
          {selectedLanguage.isPublished ? (
            <Button type="primary" shape="round" htmlType="submit" onClick={unpublishLanguage}>
              <ExportOutlined />
              Unpublish
            </Button>
          ) : null}
        </div>
      </div>
      <div>
        <Radio.Group value={filterByTranslationType} size="large" className="full-width" onChange={filterTranslation}>
          <Radio.Button value={'Web'}>Web</Radio.Button>
          <Radio.Button value={'Backend'}>Backend</Radio.Button>
          <Radio.Button value={'Mobile'}>Mobile</Radio.Button>
          <Radio.Button value={'Product'}>Product</Radio.Button>
          <Radio.Button value={'ProductTag'}>ProductTag</Radio.Button>
          <Radio.Button value={'Country'}>Country</Radio.Button>
        </Radio.Group>
      </div>
      <Table
        dataSource={translationsForDisplay}
        loading={isLoading}
        columns={columns}
        rowKey={(translation) => translation.translationId}
        scroll={{ y: 650 }}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
          position: ['bottomCenter'],
        }}
      />
    </div>
  );
}

export default TranslationTable;
