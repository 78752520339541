import axios from 'axios';
import { LoginAndPassword } from '../models/login-and-password.model';
import { LoginData } from '../models/login-data.model';

function login(data: LoginAndPassword) {
  return axios.post<LoginData>(`/auth/login`, data);
}

const AuthApi = {
  login,
};

export default AuthApi;
