import React, { CSSProperties, useEffect } from 'react';
import bwipjs from 'bwip-js';
import { v4 as uuid } from 'uuid';

type Props = {
  text?: string;
  style: CSSProperties;
};

function QrCode({ text, style }: Props) {
  const id = uuid();

  useEffect(() => {
    if (text) {
      try {
        bwipjs.toCanvas(id, {
          bcid: 'qrcode', // Barcode type
          text, // Text to encode
          scale: 3, // 3x scaling factor
        });
      } catch (e) {
        console.error('Error generating QrCode', e);
      }
    }
  }, [id, text]);

  return text ? <canvas style={style} id={id} /> : null;
}

export default QrCode;
