import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Row, Table } from 'antd';
import Search from '../../components/Search';
import { useQuery } from 'react-query';
import moment from 'moment';
import { match, useHistory, useLocation, useRouteMatch } from 'react-router';
import axios from 'axios';
import { History } from 'history';
import { dateSorter, stringSorter } from '../../utils/sorters';
import { Receipt } from '../../models/receipt.model';
import dayjs from 'dayjs';

function onChange(dateString: string[], match: match, history: History) {
  history.push({ pathname: match.path, search: `?from=${dateString[0]}&to=${dateString[1]}` });
}

const columns = [
  {
    title: 'Date and time',
    key: 'date',
    dataIndex: 'date',
    width: '40%',
    render: (date: string) => <span>{dayjs(date).format('DD-MM-YYYY HH:mm')}</span>,
    sorter: (a: Receipt, b: Receipt) => dateSorter(a.date, b.date),
  },
  {
    title: 'Link',
    key: 'id',
    dataIndex: 'id',
    width: '20%',
    render: (id: number) => (
      <Button
        type="link"
        onClick={() => {
          axios.get(`${process.env.REACT_APP_API_URL}/receipts/customer/printout/${id}`).then((value) => {
            const receiptWindow = window.open();
            receiptWindow?.document.write(value.data);
            receiptWindow?.document.close();
          });
        }}
      >
        link
      </Button>
    ),
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: ['cart', 'receiptEmail'],
    width: '20%',
    sorter: (a: Receipt, b: Receipt) => stringSorter(a.cart.receiptEmail ?? '', b.cart.receiptEmail ?? ''),
  },
  {
    title: 'Type',
    key: 'invoiceType',
    dataIndex: ['cart', 'invoiceRequested'],
    width: '20%',
    render: (invoiceRequested: boolean) => <div>{invoiceRequested ? 'Invoice' : 'Receipt'}</div>,
    sorter: (a: Receipt, b: Receipt) =>
      stringSorter(a.cart.invoiceRequested ? 'Invoice' : 'Receipt', b.cart.invoiceRequested ? 'Invoice' : 'Receipt'),
  },
];

const disabledDates = (current: dayjs.Dayjs) => current && current.isAfter(dayjs());

const dateFormat = 'YYYY-MM-DD HH:mm';

function TicketReport() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get('from');
  const to = params.get('to');
  const { data, isLoading, isError } = useQuery<Receipt[]>(
    `/receipts/customer?from=${dayjs(from).utc(true).format()}&to=${dayjs(to).utc(true).format()}`,
    {
      enabled: from && to,
    },
  );
  const [filteredTickets, setFilteredTickets] = useState(data);

  useEffect(() => {
    const sortedDataByDateDescending = data?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    setFilteredTickets(sortedDataByDateDescending);
  }, [data]);

  useEffect(() => {
    setFilteredTickets((prevState) => {
      return prevState?.filter((value) => {
        return moment(value.date).isBetween(moment(from), moment(to));
      });
    });
  }, [from, to]);

  if (!location.search) {
    history.push({
      pathname: match.path,
      search: `?from=${dayjs().subtract(1, 'month').startOf('day').format(dateFormat)}&to=${dayjs()
        .endOf('day')
        .format(dateFormat)}`,
    });
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <Row justify="start" gutter={16}>
        <Col>
          <Search autoFocus items={data ?? []} setItems={setFilteredTickets} columns={['date', 'email']} />
        </Col>
        <Col>
          <DatePicker.RangePicker
            onChange={(_, dateString) => onChange(dateString, match, history)}
            defaultValue={
              location.search
                ? [dayjs(from), dayjs(to)]
                : [dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')]
            }
            value={[dayjs(from), dayjs(to)]}
            disabledDate={disabledDates}
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            format={dateFormat}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Table
        dataSource={!isLoading ? filteredTickets : []}
        loading={isLoading}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
        }}
        scroll={{ y: 700 }}
        rowKey={(ticketReport) => ticketReport.id}
      />
    </div>
  );
}

export default TicketReport;
