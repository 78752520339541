import axios from 'axios';

function getDeliveryReport(dateFrom: string, dateTo: string) {
  return axios(`sales/city-depot?dateFrom=${dateFrom}&dateTo=${dateTo}`, { responseType: 'blob' });
}

const SalesApi = {
  getDeliveryReport,
};

export default SalesApi;
