import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select, Form } from 'antd';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { AddShop, EditShop, Shop } from '../../models/shop.model';
import { Country } from '../../models/country.model';

const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 18 },
  },
};

type Props = {
  addShop: (shop: AddShop) => void;
  editShop: (shop: EditShop) => void;
  selectedShop?: Shop;
};

function ShopForm({ addShop, editShop, selectedShop }: Props) {
  const { data: countries, isLoading } = useQuery<Country[]>('/locations/countries');
  const [form] = Form.useForm<{
    name: string;
    street: string;
    zipCode: string;
    city: string;
    countryKey: number;
    phone: string;
    taxNumber: string;
  }>();

  const onFinish = async () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();

      if (selectedShop) {
        editShop({ ...values, id: selectedShop.id });
      } else {
        addShop(values);
        form.resetFields();
      }
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, selectedShop]);

  return (
    <Form
      form={form}
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        name: selectedShop?.name,
        street: selectedShop?.street,
        zipCode: selectedShop?.zipCode,
        city: selectedShop?.city,
        countryKey: selectedShop?.countryKey,
        phone: selectedShop?.phone,
        taxNumber: selectedShop?.taxNumber,
      }}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label="Address" name="street" rules={[{ required: true, message: 'Address is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Zip code" name="zipCode" rules={[{ required: true, message: 'Zip code is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="City" name="city" rules={[{ required: true, message: 'City is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Country" name="countryKey" rules={[{ required: true, message: 'Country is required' }]}>
        <Select
          showSearch
          loading={isLoading}
          filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {countries
            ? countries.map((country) => (
                <Select.Option key={country.id} value={country.id}>
                  {country.name}
                </Select.Option>
              ))
            : null}
        </Select>
      </Form.Item>
      <Form.Item label="Phone" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Tax number" name="taxNumber">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ xs: { span: 12, offset: 12 }, sm: { span: 18, offset: 6 } }}>
        <Button type="primary" shape="round" htmlType="submit">
          <PlusOutlined /> {selectedShop ? 'Edit' : 'Add'} shop
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ShopForm;
