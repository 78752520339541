import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mantine/core';
import { Product } from '../../models/product.model';
import { History } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ScrollArea } from '@mantine/core';
import { ProductElement } from './ProductElement';
import { Divider } from 'antd';
import { TextInput } from '@mantine/core';

type Props = {
  products: Product[];
  deleteProduct: (id: string, history: History) => void;
  isLoading: boolean;
  isError: boolean;
};

function ProductList({ products, deleteProduct, isLoading, isError }: Props) {
  const location = useLocation();
  const [filter, setFilter] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    if (!filter) setFilteredProducts(products);
    else {
      const filterLowerCase = filter.toLowerCase();
      setFilteredProducts(
        products
          .filter(Boolean)
          .filter(
            (x) =>
              x.name.toLowerCase().includes(filterLowerCase) ||
              x.description.toLowerCase().includes(filterLowerCase) ||
              x.defaultPrice.toString().includes(filterLowerCase),
          ),
      );
    }
  }, [filter, products]);

  return (
    <>
      <div className="h-full flex flex-column">
        <div className="p-2 flex justify-content-between">
          <Link to={`${location.pathname}/add`}>
            <Button leftIcon={<FontAwesomeIcon icon={faPlus} />}>Add new product</Button>
          </Link>
          <TextInput
            icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            placeholder="Filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <Divider className="m-2" />
        {!isError ? (
          <ScrollArea>
            <div className="p-3 flex flex-wrap justify-content-around gap-4 w-full">
              {filteredProducts.map((x, i) => (
                <ProductElement product={x} deleteProduct={deleteProduct} key={i} />
              ))}
            </div>
          </ScrollArea>
        ) : (
          <div>Something went wrong</div>
        )}
      </div>
      {/* <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col>
          <Link to={`${location.pathname}/add`}>
            <Button leftIcon={<FontAwesomeIcon icon={faPlus} />}>Add new product</Button>
          </Link>
        </Col>
        <Col>
          <Search autoFocus items={products} setItems={setFilteredProducts} columns={['name', 'category', 'price']} />
        </Col>
      </Row>

      <Divider style={{ height: 0 }} />

      {!isError ? (
        <List
          grid={{ gutter: 16, xs: 1, md: 2, lg: 4, xl: 4, xxl: 4 }}
          loading={isLoading}
          dataSource={filledList ?? []}
          renderItem={(product) => (
            <List.Item>
              {product.name ? (
                <Link to={{ pathname: `/products/edit/${product.id}` }}>
                  <ProductElement product={product} deleteProduct={deleteProduct} />
                </Link>
              ) : (
                <EmptyProductCard />
              )}
            </List.Item>
          )}
          pagination={{
            showSizeChanger: false,
            pageSize: productsOnPage,
            className: 'pagination',
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      ) : (
        <div>Something went wrong</div>
      )}*/}
    </>
  );
}

export default ProductList;
