import React, { useEffect, useState } from 'react';
import { Col, Input, InputNumber, Row, Select, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import useTags from '../../hooks/useTags';
import ImageUpload from '../../components/ImageUpload';
import Barcode from './Barcode';
import useSuppliers from '../../hooks/useSuppliers';
import { Product } from '../../models/product.model';
import { ProductTag } from '../../models/product-tag.model';
import { Image } from '../../models/image.model';
import { Button } from '@mantine/core';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

const EAN13_LENGTH = 13;

function validateEAN13(ean: string) {
  const checkSum = ean.split('').reduce((p, v, i) => (i % 2 === 0 ? p + parseInt(v, 10) : p + 3 * parseInt(v, 10)), 0);

  return checkSum % 10 === 0;
}

type Props = {
  processProduct: (product: Product) => void;
  product?: Product;
  products: Product[];
};

function ProductForm({ processProduct, product, products }: Props) {
  const history = useHistory();
  const location = useLocation<{ code?: string }>();
  const [barcode, setBarcode] = useState<string>();
  const [image, setImage] = useState<Image>(null);
  const { tags } = useTags();
  const { suppliers } = useSuppliers();
  const [otherProducts, setOtherProducts] = useState<Product[] | null>(null);
  const [form] = Form.useForm<{
    barcode?: string;
    name: string;
    defaultPrice: number;
    tags: ProductTag[];
    supplierId: string;
    description: string;
    vatRate: number;
    type: string;
  }>();

  useEffect(() => {
    setOtherProducts(product ? products.filter((y) => y.id !== product.id) : products);
  }, [product, products]);

  useEffect(() => {
    if (location.state?.code) {
      setBarcode(location.state.code);
    } else if (product) {
      setBarcode(product.barcode);
    }
  }, [location.state, product]);

  useEffect(() => {
    form.resetFields();
  }, [product, barcode, form]);

  const onFinish = async () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      const processedProduct: Product & { oldBarcode?: string; image: Image } = {
        ...values,
        defaultPrice: values.defaultPrice,
        oldBarcode: product?.barcode,
        image,
        imageHandle: product?.imageHandle,
        tags: values.tags?.map((tag) => ({ name: tag.name })),
        supplierId: values.supplierId,
        id: product?.id!,
      };
      processProduct(processedProduct);
      history.push('/products');
    });
  };

  return (
    <Row>
      <Col
        xs={{ span: 24, order: 2 }}
        md={{ span: 4, order: 1 }}
        style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexFlow: 'column' }}
      >
        <Barcode text={barcode} />
        <ImageUpload image={image} handle={product?.imageHandle} setImage={setImage} />
      </Col>
      <Col xs={{ span: 24, order: 1 }} md={{ span: 20, order: 2 }}>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            barcode: barcode,
            name: product?.name,
            defaultPrice: product?.defaultPrice,
            tags: product?.tags?.map((tag) => tag.name),
            supplierId: product?.supplierId,
            description: product?.description,
            vatRate: product?.vatRate,
            type: product?.type,
          }}
        >
          <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please select type' }]}>
            <Select showArrow>
              <Select.Option key={1} value={1}>
                {'Product'}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="EAN"
            name="barcode"
            rules={[
              {
                validator: (rule, value) => {
                  if (!value || value.length === 0) {
                    return Promise.resolve();
                  }

                  if (value.length < EAN13_LENGTH) {
                    return Promise.reject('EAN requires 13 characters');
                  }

                  if (value.length === EAN13_LENGTH) {
                    const isValid = validateEAN13(value);
                    return !isValid ? Promise.reject('EAN13 code is not valid') : Promise.resolve();
                  }

                  const barcodeUsed = otherProducts?.some(
                    (product) => product.barcode?.toLocaleLowerCase() === value.toLocaleLowerCase(),
                  );
                  return barcodeUsed ? Promise.reject('Product with this barcode already exists') : Promise.resolve();
                },
              },
            ]}
          >
            <Input
              autoFocus
              maxLength={EAN13_LENGTH}
              onChange={(e) => {
                setBarcode(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: 'Please input name' },
              {
                validator: (rule, value) => {
                  const nameUsed = otherProducts?.some(
                    (product) => product.name.toLocaleLowerCase() === value.toLocaleLowerCase(),
                  );
                  return nameUsed ? Promise.reject() : Promise.resolve();
                },
                message: 'Product with this name already exists',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Price"
            name="defaultPrice"
            rules={[
              { required: true, message: 'Please input price' },
              {
                validator: (rule, value) => {
                  return value < 0 || value === 0 ? Promise.reject('Price must be bigger than 0') : Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber precision={2} min={0} />
          </Form.Item>
          <Form.Item
            label="VAT Rate"
            name="vatRate"
            rules={[
              { required: true, message: 'Please input VAT rate' },
              {
                validator: (rule, value) => {
                  return value < 0 || value === 0
                    ? Promise.reject('VAT rate must be bigger than 0')
                    : Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber precision={0} min={0} />
          </Form.Item>
          <Form.Item label="Tags" name="tags">
            <Select mode="multiple" loading={!tags} showArrow>
              {tags
                ? tags.map((tag) => (
                    <Select.Option key={tag.name} value={tag.name}>
                      {tag.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item label="Supplier" name="supplierId" rules={[{ required: true, message: 'Please select supplier' }]}>
            <Select loading={!suppliers} showArrow>
              {suppliers
                ? suppliers.map((supplier) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 18, offset: 6 } }}>
            <Button type="submit" leftIcon={<FontAwesomeIcon icon={faPenToSquare} />}>
              {product ? 'Edit' : 'Add'} product
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default ProductForm;
