import XLSX from 'xlsx';
import moment from 'moment';
import { LotReportItem } from '../../models/lot-report-item.model';
import { StockItemsReportItem } from '../../models/stock-items-report-item.model';
import { SalesReportItem } from '../../models/sales-report-item.model';

function exportBatchReport(batches: LotReportItem[]) {
  const dataForExport = batches.map(({ shops, ...batch }) => ({
    Value: batch.value,
    'Expiry date': batch.expiryDate ? moment(batch.expiryDate).toDate() : 'N/A',
    'Sold items': batch.itemsSold,
    'Left items': batch.itemsLeft,
    'Expired items': batch.itemsExpired,
    ...shops.reduce((acc: { [x: string]: string }, cur, i) => {
      acc[`Shop ${i + 1}`] = cur;
      return acc;
    }, {}),
  }));

  const workSheet = XLSX.utils.json_to_sheet(dataForExport, { dateNF: 'dd-mm-yyyy hh:mm', cellDates: true });
  workSheet['!cols'] = [{ width: 20 }, { width: 30 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 20 }];

  const workBook = XLSX.utils.book_new();
  workBook.Props = { Title: 'Batch Report', CreatedDate: moment().toDate() };

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Batches');
  const filename = `batch-report_${moment().format('DD-MM-YYYY')}.xlsx`;
  XLSX.writeFile(workBook, filename);
}

function exportSalesReport(stockItems: SalesReportItem[], from: string | null, to: string | null) {
  const dataForExport = stockItems.map((si) => ({
    ShopName: si.shopName,
    ProductName: si.productName,
    Quantity: 1,
    SaleDate: moment(si.saleDate).toDate(),
    Price: si.price,
    NetPrice: si.netPrice,
    VatRate: si.vatRate,
    PaymentType: si.paymentType,
  }));

  const workSheet = XLSX.utils.json_to_sheet(dataForExport, { dateNF: 'dd-mm-yyyy hh:mm', cellDates: true });
  workSheet['!cols'] = [
    { width: 40 },
    { width: 40 },
    { width: 10 },
    { width: 15 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 15 },
  ];

  const workBook = XLSX.utils.book_new();
  workBook.Props = { Title: `Sales Report`, CreatedDate: moment().toDate() };
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sold items');

  const filename = `sales-report_${moment(from).format('DD-MM-YYYY')}-${moment(to).format('DD-MM-YYYY')}.xlsx`;
  XLSX.writeFile(workBook, filename);
}

function exportStockItemsReport(stockItems: StockItemsReportItem[]) {
  const dataForExport = stockItems.map((stockItem) => ({
    Shop: stockItem.shopName,
    Product: stockItem.productName,
    Quantity: stockItem.quantity,
  }));

  const workSheet = XLSX.utils.json_to_sheet(dataForExport, { dateNF: 'dd-mm-yyyy hh:mm' });
  workSheet['!cols'] = [{ width: 20 }, { width: 30 }, { width: 10 }];

  const workBook = XLSX.utils.book_new();
  workBook.Props = { Title: 'Stock Report', CreatedDate: moment().toDate() };

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Stock items');
  const filename = `stock-report_${moment().format('DD-MM-YYYY')}.xlsx`;
  XLSX.writeFile(workBook, filename);
}

export { exportBatchReport, exportSalesReport, exportStockItemsReport };
