import React, { useEffect, useState } from 'react';
import bwipjs from 'bwip-js';

type Props = {
  text?: string;
};

function Barcode({ text }: Props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (text) {
      try {
        setError(false);
        bwipjs.toCanvas('barcode', {
          bcid: 'ean13', // Barcode type
          text, // Text to encode
          scale: 3, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: 'center', // Always good to set this
        });
      } catch (e) {
        setError(true);
      }
    }
  }, [text]);

  return (
    <div style={{ display: 'flex', height: 150, width: '100%', alignItems: 'center' }}>
      <canvas id="barcode" style={{ display: error || text?.length !== 13 ? 'none' : 'flex', width: '100%' }} />
    </div>
  );
}

export default Barcode;
