import { useEffect, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Statistic, Table } from 'antd';
import Search from '../../components/Search';
import { dateSorter, numberSorter, stringSorter } from '../../utils/sorters';
import { exportSalesReport } from './exportToXlsx';
import { match, useHistory, useLocation, useRouteMatch } from 'react-router';
import { History } from 'history';
import { useQuery } from 'react-query';
import { SalesReportItem } from '../../models/sales-report-item.model';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const columns = [
  {
    title: 'Shop name',
    key: 'shopName',
    dataIndex: 'shopName',
    sorter: (a: SalesReportItem, b: SalesReportItem) => stringSorter(a.shopName, b.shopName),
  },
  {
    title: 'Product name',
    key: 'productName',
    dataIndex: 'productName',
    sorter: (a: SalesReportItem, b: SalesReportItem) => stringSorter(a.productName, b.productName),
  },
  {
    title: 'Sale date',
    key: 'saleDate',
    dataIndex: 'saleDate',
    render: (date: string) => dayjs(date).add(dayjs().utcOffset(), 'minute').format('DD-MM-YYYY HH:mm'),
    sorter: (a: SalesReportItem, b: SalesReportItem) => dateSorter(a.saleDate, b.saleDate),
  },
  {
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
    render: (price: number) => price + '€',
    sorter: (a: SalesReportItem, b: SalesReportItem) => numberSorter(a.price, b.price),
  },
  {
    title: 'Net price',
    key: 'netPrice',
    dataIndex: 'netPrice',
    render: (netPrice: number) => netPrice.toFixed(2) + '€',
    sorter: (a: SalesReportItem, b: SalesReportItem) => numberSorter(a.netPrice, b.netPrice),
  },
  {
    title: 'VAT rate',
    key: 'vatRate',
    dataIndex: 'vatRate',
    render: (rate: number) => rate + '%',
    sorter: (a: SalesReportItem, b: SalesReportItem) => numberSorter(a.vatRate, b.vatRate),
  },
  {
    title: 'PaymentType',
    key: 'paymentType',
    dataIndex: 'paymentType',
    sorter: (a: SalesReportItem, b: SalesReportItem) => stringSorter(a.paymentType, b.paymentType),
  },
];

type Props = {
  salesReport: SalesReportItem[];
  isLoading: boolean;
};

function SoldItemsTable({ salesReport, isLoading }: Props) {
  const [filteredSalesReport, setFilteredSalesReport] = useState(salesReport);

  useEffect(() => {
    setFilteredSalesReport(salesReport);
  }, [salesReport]);

  return (
    <>
      <Search
        autoFocus
        items={salesReport ?? []}
        setItems={setFilteredSalesReport}
        columns={['productName', 'shopName', 'paymentType']}
      />
      <br />
      <br />
      <Table
        dataSource={filteredSalesReport ?? []}
        loading={isLoading}
        columns={columns}
        scroll={{ y: 600 }}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
        }}
        rowKey={() => uuid()}
      />
    </>
  );
}

const onChange = (dateString: string[], match: match, history: History) => {
  history.push({ pathname: match.path, search: `?from=${dateString[0]}&to=${dateString[1]}` });
};

const salesReportUrl = (from: string | null, to: string | null) => {
  to = dayjs(to).add(1, 'day').format('YYYY-MM-DD');
  return `/sales-report?from=${dayjs(from).utc(true).format()}&to=${dayjs(to).utc(true).format()}`;
};

const disabledDates = (current: dayjs.Dayjs) => current && current.isAfter(dayjs());

function SalesReport() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get('from');
  const to = params.get('to');
  const { data: salesReport, isLoading, isError } = useQuery<SalesReportItem[]>(salesReportUrl(from, to), {
    enabled: from && to,
  });
  const [totalSoldItems, setTotalSoldItems] = useState(0);

  useEffect(() => {
    const total = salesReport ? salesReport.length : 0;
    setTotalSoldItems(total);
  }, [salesReport]);

  if (!location.search) {
    history.push({
      pathname: match.path,
      search: `?from=${dayjs().add(-6, 'day').startOf('day').format('YYYY-MM-DD')}&to=${dayjs()
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DD')}`,
    });
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <>
      <Row justify="start" gutter={16}>
        <Col>
          <DatePicker.RangePicker
            onChange={(_, dateString) => onChange(dateString, match, history)}
            defaultValue={
              location.search
                ? [dayjs(from), dayjs(to)]
                : [dayjs().add(-6, 'day').startOf('day'), dayjs().startOf('day')]
            }
            disabledDate={disabledDates}
            allowClear={false}
          />
        </Col>
        <Col>
          <Button type="default" onClick={() => exportSalesReport(salesReport ?? [], from, to)}>
            <ExportOutlined /> Export
          </Button>
        </Col>
        <Col>
          <Statistic title="Sold items" value={totalSoldItems} style={{ margin: '0 20px' }} />
        </Col>
      </Row>
      <SoldItemsTable salesReport={salesReport ?? []} isLoading={isLoading} />
    </>
  );
}

export default SalesReport;
