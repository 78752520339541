import {
  BarChartOutlined,
  ContainerOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagOutlined,
  FlagOutlined,
  BarcodeOutlined,
  DropboxOutlined,
  RedEnvelopeOutlined,
  CarOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import GenerateQrCode from './GenerateQrCode';

const items = [
  {
    key: 'products',
    icon: <ShoppingOutlined />,
    label: <NavLink to="/products">Products</NavLink>,
  },
  {
    key: 'stock-items',
    icon: <ContainerOutlined />,
    label: <NavLink to="/stock-items">Stock Items</NavLink>,
  },
  {
    key: 'stock-pointers',
    icon: <BarcodeOutlined />,
    label: <NavLink to="/stock-pointers">Stock Pointers</NavLink>,
  },
  {
    key: 'management',
    icon: <SettingOutlined />,
    label: 'Management',
    children: [
      {
        key: 'qr-codes',
        icon: <QrcodeOutlined />,
        label: <NavLink to="/qr-codes">QR Codes</NavLink>,
      },
      {
        key: 'tags',
        icon: <TagOutlined />,
        label: <NavLink to="/tags">Tags</NavLink>,
      },
      {
        key: 'shops',
        icon: <ShopOutlined />,
        label: <NavLink to="/shops">Shops</NavLink>,
      },
    ],
  },
  {
    key: 'report',
    icon: <BarChartOutlined />,
    label: 'Reports',
    children: [
      {
        key: 'sales-report',
        icon: <LineChartOutlined />,
        label: <NavLink to="/sales-report">Sales report</NavLink>,
      },
      {
        key: 'batch-report',
        icon: <ContainerOutlined />,
        label: <NavLink to="/batch-report">Batch report</NavLink>,
      },
      {
        key: 'stock-items-report',
        icon: <DropboxOutlined />,
        label: <NavLink to="/stock-items-report">Stock report</NavLink>,
      },
      {
        key: 'ticket-report',
        icon: <RedEnvelopeOutlined />,
        label: <NavLink to="/ticket-report">Ticket report</NavLink>,
      },
      {
        key: 'delivery-report',
        icon: <CarOutlined />,
        label: <NavLink to="/delivery-report">Delivery report</NavLink>,
      },
    ],
  },
  {
    key: 'translations',
    icon: <FlagOutlined />,
    label: <NavLink to="/translations">Translations</NavLink>,
  },
];

type Props = {
  collapsed: boolean;
};

function SideNav({ collapsed }: Props) {
  const location = useLocation();

  return (
    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Menu
        mode="inline"
        style={{ flex: 1 }}
        selectedKeys={[location.pathname.split('/').filter((i) => i)[0]]}
        items={items}
      />
      {!collapsed && <GenerateQrCode />}
    </div>
  );
}

export default SideNav;
