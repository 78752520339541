import React, { createContext, useCallback, useContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import AuthApi from 'api/authApi';
import { ROLE_SHOP_ASSISTANT } from 'utils/constants';
import { clearToken, setToken } from '../api/axios-config';
import StorageKeys from '../storage-keys';
import { LoginData } from '../models/login-data.model';

type Context = {
  login: (form: any) => Promise<boolean>;
  logout: () => void;
  auth: LoginData | null;
};

const AuthContext = createContext<Context | null>(null);

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within AuthProvider`);
  }
  return context;
}

function AuthProvider(props: any) {
  const { storedValue: auth, setValue: setAuth } = useLocalStorage<LoginData | null>(StorageKeys.loginData, null);

  const login = useCallback(
    (form) => {
      return AuthApi.login(form).then((response) => {
        const authInfo = response.data;

        if (authInfo.user.roles.filter((r) => r === ROLE_SHOP_ASSISTANT).length === 0) {
          return false;
        }

        setAuth(authInfo);
        setToken(authInfo);
        return true;
      });
    },
    [setAuth],
  );

  const logout = useCallback(() => {
    clearToken();
    setAuth(null);
  }, [setAuth]);

  return <AuthContext.Provider value={{ login, logout, auth }} {...props} />;
}

export { useAuth, AuthProvider };
