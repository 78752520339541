import { PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { Image } from '../models/image.model';

function getBase64(img: File | Blob | undefined, callback: (result: string | ArrayBuffer | null) => void) {
  if (!img) {
    return;
  }

  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: RcFile) {
  const isImage = ['image/jpeg', 'image/svg+xml', 'image/png'].includes(file.type);

  if (!isImage) {
    message.error('You can only upload JPG, PNG or SVG files!');
  }

  return isImage;
}

function UploadButton() {
  return (
    <div style={{ width: '100%' }}>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
}

type ImagePreviewProps = {
  data?: string;
  image?: Image;
};

function ImagePreview({ data, image }: ImagePreviewProps) {
  if (image || data) {
    return <img src={image ?? data} style={{ width: '100%' }} alt="avatar" />;
  }

  return <UploadButton />;
}

type ImageUploadProps = {
  image?: Image;
  handle?: string;
  setImage: (imageUrl: string | null) => void;
};

function ImageUpload({ image, handle, setImage }: ImageUploadProps) {
  const { data } = useQuery<string>(`/products/image/${handle}/base64`, {
    enabled: !!handle,
  });

  const handleChange = (info: UploadChangeParam) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      if (typeof imageUrl === 'object') {
        return;
      }

      setImage(imageUrl);
    });
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      <ImagePreview data={data} image={image} />
    </Upload>
  );
}

export default ImageUpload;
