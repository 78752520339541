import { Button } from 'primereact/button';

interface Props {
  header: string;
  showDeleteButton: boolean;
  saveButtonDisabled: boolean;
  deleteButtonDisabled?: boolean;
  onSaveClick: () => Promise<any> | void;
  onCancelClick: () => Promise<any> | void;
  onDeleteClick?: () => Promise<any> | void;
}

export function FormHeader({
  header,
  showDeleteButton,
  saveButtonDisabled,
  deleteButtonDisabled,
  onSaveClick,
  onCancelClick,
  onDeleteClick,
}: Props) {
  return (
    <div className="flex justify-content-between align-items-center">
      <div className="text-xl font-bold">{header}</div>
      <div className="flex justify-content-end gap-2">
        <Button
          size="small"
          label="Save"
          className="save-button"
          icon="pi pi-save"
          disabled={saveButtonDisabled}
          onClick={onSaveClick}
        />
        <Button size="small" label="Cancel" className="cancel-button" icon="pi pi-times" onClick={onCancelClick} />
        {showDeleteButton && (
          <Button
            size="small"
            label="Delete"
            className="delete-button"
            icon="pi pi-trash"
            disabled={deleteButtonDisabled}
            onClick={onDeleteClick}
          />
        )}
      </div>
    </div>
  );
}
