import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, List, Select } from 'antd';
import TranslationsApi from 'api/translationsApi';
import { Language } from '../../models/language.model';
import { useQuery } from 'react-query';

type Props = {
  selectedLanguage?: Language;
  setSelectedLanguage: (language: Language) => void;
};

function LanguagesTable({ selectedLanguage, setSelectedLanguage }: Props) {
  const [languageIdToAdd, setLanguageIdToAdd] = useState<number>();
  const { data: allLanguages, isLoading, error } = useQuery<Language[]>('/translations/languages');

  const addSingleLanguage = () => {
    if (!languageIdToAdd) {
      return;
    }

    TranslationsApi.selectLanguage(languageIdToAdd).then(() => {
      setLanguageIdToAdd(undefined);
    });
  };

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minWidth: '300px' }}>
      <div>
        <Select
          autoFocus
          style={{ width: 'calc(100% - 85px)' }}
          placeholder="Select language"
          value={languageIdToAdd}
          onChange={(value) => setLanguageIdToAdd(value)}
        >
          {allLanguages
            ? allLanguages
                .filter((language) => !language.isSelected)
                .map((language) => (
                  <Select.Option key={language.id} value={language.id}>
                    {language.name}
                  </Select.Option>
                ))
            : null}
        </Select>
        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          onClick={addSingleLanguage}
          disabled={!languageIdToAdd}
          style={{ verticalAlign: 'top', marginLeft: '5px' }}
        >
          <PlusOutlined /> Add
        </Button>
      </div>
      <List
        style={{ maxWidth: '500px' }}
        dataSource={allLanguages ? allLanguages.filter((language) => language.isSelected) : []}
        loading={isLoading}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
        }}
        renderItem={(language) => (
          <List.Item
            onClick={() => setSelectedLanguage(language)}
            className={'list-row ' + (language?.id === selectedLanguage?.id && 'selected')}
          >
            {language.name} {language.isPublished ? <div>Published</div> : null}
          </List.Item>
        )}
      />
    </div>
  );
}

export default LanguagesTable;
