import axios from 'axios';
import StorageKeys from '../storage-keys';
import { RestError } from '../models/rest-error.model';
import { LoginData } from '../models/login-data.model';
import Constants from 'common/constants';

let unauthorizedCallback: () => void;

const tokenString = localStorage.getItem(StorageKeys.loginData);
export let token: LoginData | null = tokenString ? JSON.parse(tokenString) : null;

export const setToken = (value: LoginData) => {
  token = value;
};

export const clearToken = () => {
  token = null;
};

function init() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common.ApiVersion = Constants.API_VERSION;

  axios.interceptors.request.use(async (config) => {
    if (token) {
      config.headers.Authorization = token.accessToken;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        unauthorizedCallback();
      }

      let message = 'error.errorDefault';
      if (error.response.data.message) {
        message = error.response.data.message;
      } else {
        switch (error.response.status) {
          case 500:
            message = 'error.internalServerError';
            break;
          case 401:
            message = 'error.unauthorized';
            break;
          case 404:
            message = 'error.notFound';
            break;
          case 409:
            message = 'error.conflict';
            break;
          case 426:
            message = 'error.unsupportedApiVersion';
            break;
        }
      }

      const restError: RestError = {
        statusCode: error.response.status,
        message,
      };

      return Promise.reject(restError);
    },
  );
}

function setUnauthorizedCallback(callback: () => void) {
  unauthorizedCallback = callback;
}

const Axios = {
  init,
  setUnauthorizedCallback,
};

export default Axios;
