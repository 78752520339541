import { useEffect, useState } from 'react';
import TagsApi from '../api/tagsApi';
import { notifyError } from '../utils/notify';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { AddProductTag, ProductTag } from '../models/product-tag.model';
import { RestError } from '../models/rest-error.model';

const addTag = async (tag: AddProductTag) => {
  const { data } = await TagsApi.post(tag);
  return data;
};

const updateTag = async (tag: ProductTag) => {
  const { data } = await TagsApi.put(tag);
  return data;
};

const removeTag = async (tagId: number) => {
  const { data } = await TagsApi.remove(tagId);
  return data;
};

const queryKey = `/tags/all`;

function useTags() {
  const queryCache = useQueryCache();
  const { data, isLoading, isError } = useQuery<ProductTag[]>(queryKey);

  const [add] = useMutation<ProductTag, RestError, AddProductTag, ProductTag[]>(addTag, {
    onMutate: (newTodo) => {
      queryCache.cancelQueries(queryKey);
      const previousTags = queryCache.getQueryData<ProductTag[]>(queryKey);
      queryCache.setQueryData<ProductTag[]>(queryKey, (old) => {
        return [{ ...newTodo, id: 0 }, ...old!];
      });

      return previousTags!;
    },
    onError: (error, newTodo, previousTags) => {
      notifyError(error.statusCode);
      queryCache.setQueryData<ProductTag[]>(queryKey, previousTags);
    },
    onSettled: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const [update] = useMutation<ProductTag, RestError, ProductTag, ProductTag[]>(updateTag, {
    onMutate: (updatedTag) => {
      queryCache.cancelQueries(queryKey);
      const previousTags = queryCache.getQueryData<ProductTag[]>(queryKey);
      queryCache.setQueryData<ProductTag[]>(queryKey, (old) => {
        return [updatedTag, ...old!.filter((x) => x.id !== updatedTag.id)];
      });

      return previousTags!;
    },

    onError: (error, updatedTag, previousTags) => {
      notifyError(error.statusCode);
      queryCache.setQueryData<ProductTag[]>(queryKey, previousTags);
    },

    onSettled: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const [remove] = useMutation<ProductTag, RestError, number, ProductTag[]>(removeTag, {
    onMutate: (tagId) => {
      queryCache.cancelQueries(queryKey);
      const previousTags = queryCache.getQueryData<ProductTag[]>(queryKey);
      queryCache.setQueryData<ProductTag[]>(queryKey, (old) => {
        return old!.filter((tag) => tag.id !== tagId);
      });

      return previousTags!;
    },
    onError: (error, newTodo, previousTags) => {
      notifyError(error.statusCode);
      queryCache.setQueryData(queryKey, previousTags);
    },
    onSettled: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });
  const [tags, setTags] = useState(data ?? []);

  useEffect(() => {
    if (data) {
      setTags(data);
    }
  }, [data]);

  return { tags, isLoading, isError, add, update, remove };
}

export default useTags;
