import React, { useState } from 'react';
import LanguagesTable from 'views/Translations/LanguagesTable';
import TranslationTable from 'views/Translations/TranslationTable';
import { Language } from '../../models/language.model';

function Translations() {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  return (
    <div style={{ display: 'flex' }}>
      <LanguagesTable selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      {selectedLanguage ? (
        <TranslationTable selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      ) : null}
    </div>
  );
}

export default Translations;
