import React, { useEffect } from 'react';
import { useAuth } from './context/AuthContext';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import axios from 'axios';
import Axios from './api/axios-config';
import { Button } from '@mantine/core';

const defaultQueryFn = async (key: string) => {
  const { data } = await axios.get(key);
  return data;
};

export const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

function App() {
  const { auth, logout } = useAuth();

  useEffect(() => {
    Axios.setUnauthorizedCallback(() => {
      logout();
    });
  }, [logout]);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {auth ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </ReactQueryCacheProvider>
  );
}

export default App;
