import { useEffect, useState } from 'react';
import ProductApi from '../api/productApi';
import { notifyError } from '../utils/notify';
import { useQuery } from 'react-query';
import { Product } from '../models/product.model';
import { History } from 'history';

function useProducts() {
  const { data, isLoading, isError } = useQuery<Product[]>('/products');
  const [products, setProducts] = useState(data ?? []);

  const addProduct = async (product: Product) => {
    const oldProducts = products;
    setProducts((prevProducts) => [product, ...prevProducts]);

    ProductApi.post(product)
      .then((value) => {
        setProducts([value.data, ...oldProducts]);
      })
      .catch((error) => {
        notifyError(error.statusCode);
        setProducts(oldProducts);
      });
  };

  const editProduct = async (newProduct: Product) => {
    const oldProducts = products;
    setProducts((prevProducts) =>
      prevProducts.map((prevProduct) => {
        if (prevProduct.id === newProduct.id) {
          return { ...prevProduct, ...newProduct };
        }
        return prevProduct;
      }),
    );

    ProductApi.put(newProduct).catch((error) => {
      setProducts(oldProducts);
      notifyError(error.status);
    });
  };

  const deleteProduct = (productId: string, history: History) => {
    const unblock = history.block();
    const oldProducts = products;
    setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId));

    ProductApi.remove(productId)
      .catch((error) => {
        setProducts(oldProducts);
        notifyError(error.statusCode);
      })
      .finally(() => {
        unblock();
      });
  };

  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  }, [data]);

  return { products, isLoading, isError, addProduct, editProduct, deleteProduct };
}

export default useProducts;
