import useMedia from './useMedia';
import { useEffect, useState } from 'react';

function useCollapse() {
  const media = useMedia(['(min-width: 1000px)'], [false], true);
  const [collapsed, setCollapsed] = useState(media);

  useEffect(() => setCollapsed(media), [media]);

  return { collapsed, setCollapsed };
}

export default useCollapse;
