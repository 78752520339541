import React from 'react';
import { Button, Card, Checkbox, Input, message, Form } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router';

function Login() {
  const { login } = useAuth();
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async () => {
    const values = await form.validateFields();

    const loginResult = await login(values);

    if (!loginResult) {
      form.resetFields();
      message.error('Wrong login or password');
      return;
    }

    history.push('/');
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card style={{ maxWidth: '300px' }}>
        <Form form={form} onFinish={onFinish} initialValues={{ remember: true }}>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Login;
