import axios from 'axios';
import { Translation } from '../models/translation.model';
import { TranslationValue } from '../models/translation-value.model';
import { Language } from '../models/language.model';

function getAllLanguages() {
  return axios.get<Language[]>('/translations/languages');
}

function getAllPublishedLanguages() {
  return axios.get<Language[]>('/translations/languages/published');
}

function getAllSelectedLanguages() {
  return axios.get<Language[]>('/translations/languages/selected');
}

function selectLanguage(id: number) {
  return axios.get(`/translations/languages/${id}/select`);
}

function unselectLanguage(id: number) {
  return axios.get(`/translations/languages/${id}/unselect`);
}

function publishLanguage(id: number) {
  return axios.get(`/translations/languages/${id}/publish`);
}

function unpublishLanguage(id: number) {
  return axios.get(`/translations/languages/${id}/unpublish`);
}

function getTranslationsForLanguageId(id: number) {
  return axios.get<TranslationValue[]>(`/translations/translations/${id}`);
}

function getTranslationsForLanguageCulture(culture: string) {
  return axios.get<TranslationValue[]>(`/translations/translations/${culture}`);
}

function setTranslation(model: Translation) {
  return axios.post('/translations/translations', model);
}

const TranslationsApi = {
  getAllLanguages,
  getAllPublishedLanguages,
  getAllSelectedLanguages,
  selectLanguage,
  unselectLanguage,
  publishLanguage,
  unpublishLanguage,
  getTranslationsForLanguageId,
  getTranslationsForLanguageCulture,
  setTranslation,
};

export default TranslationsApi;
