import React, { useState } from 'react';
import StockItemTable from './StockItemTable';
import { Col, Row } from 'antd';

function StockItems() {
  const [selectedId, setSelectedId] = useState<number>();

  return (
    <Row gutter={16}>
      <Col span={24}>
        <StockItemTable selectedId={selectedId} setSelectedId={setSelectedId} />
      </Col>
    </Row>
  );
}

export default StockItems;
