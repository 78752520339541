import React from 'react';
import { Input } from 'antd';
import Fuse from 'fuse.js';
import { SearchProps } from 'antd/es/input';

type Props<T> = {
  items: T[];
  setItems: (items: T[]) => void;
  columns: string[];
  autoFocus?: boolean;
} & SearchProps;

function Search<T>({ items, setItems, columns, autoFocus, placeholder, ...props }: Props<T>) {
  const fuse = new Fuse(items, { keys: columns });

  const search = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      return fuse.search(event.target.value).map((y) => y.item);
    }

    return items;
  };

  return (
    <Input.Search
      autoFocus={autoFocus}
      placeholder={placeholder ?? 'input search text'}
      onChange={(event) => setItems(search(event))}
      style={{ width: 200 }}
      {...props}
    />
  );
}

export default Search;
