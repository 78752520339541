import React, { useEffect, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { Row, Col, Table, Button } from 'antd';
import Search from '../../components/Search';
import { dateSorter, numberSorter, stringSorter } from '../../utils/sorters';
import { exportBatchReport } from './exportToXlsx';
import { useQuery } from 'react-query';
import { LotReportItem } from '../../models/lot-report-item.model';
import dayjs from 'dayjs';

const columns = [
  {
    title: 'Value',
    key: 'value',
    dataIndex: 'value',
    width: '30%',
    sorter: (a: LotReportItem, b: LotReportItem) => stringSorter(a.value ?? '', b.value ?? ''),
  },
  {
    title: 'Expiry date',
    key: 'expiryDate',
    dataIndex: 'expiryDate',
    width: '30%',
    render: (expiryDate?: string) => (expiryDate ? dayjs(expiryDate).format('DD-MM-YYYY') : 'N/A'),
    sorter: (a: LotReportItem, b: LotReportItem) => dateSorter(a.expiryDate ?? 0, b.expiryDate ?? 0),
  },
  {
    title: 'Sold items',
    key: 'itemsSold',
    dataIndex: 'itemsSold',
    width: '10%',
    sorter: (a: LotReportItem, b: LotReportItem) => numberSorter(a.itemsSold, b.itemsSold),
  },
  {
    title: 'Left items',
    key: 'itemsLeft',
    dataIndex: 'itemsLeft',
    width: '10%',
    sorter: (a: LotReportItem, b: LotReportItem) => numberSorter(a.itemsLeft, b.itemsLeft),
  },
  {
    title: 'Expired items',
    key: 'itemsExpired',
    dataIndex: 'itemsExpired',
    width: '10%',
    sorter: (a: LotReportItem, b: LotReportItem) => numberSorter(a.itemsExpired, b.itemsExpired),
  },
  {
    title: 'Shops',
    key: 'shops',
    dataIndex: 'shops',
    width: '10%',
    render: (shops: string[]) => shops.join(',\n'),
    sorter: (a: LotReportItem, b: LotReportItem) => stringSorter(a.shops.join(',\n'), b.shops.join(',\n')),
  },
];

function BatchReport() {
  const { data: batchReport, isLoading, isError } = useQuery<LotReportItem[]>('/lot-report');
  const [filteredBatches, setFilteredBatches] = useState(batchReport);

  useEffect(() => {
    if (!batchReport) {
      return;
    }

    setFilteredBatches(batchReport);
  }, [batchReport]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <Row justify="start" gutter={16}>
        <Col>
          <Search autoFocus items={batchReport ?? []} setItems={setFilteredBatches} columns={['value', 'expiryDate']} />
        </Col>
        <Col>
          <Button type="default" onClick={() => exportBatchReport(batchReport ?? [])}>
            <ExportOutlined /> Export
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table
        dataSource={!isLoading ? filteredBatches : []}
        loading={isLoading}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
        }}
        scroll={{ y: 650 }}
        rowKey={(batchReport) => batchReport.value}
      />
    </div>
  );
}

export default BatchReport;
