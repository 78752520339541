import React, { useState } from 'react';
import { InputNumber, Form, Button, Typography } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import bwipjs from 'bwip-js';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';

const { Text } = Typography;

function generateGuids(quantity: number) {
  let guids = [];

  for (let i = 0; i < quantity; i++) {
    guids.push('SmartS.' + uuid());
  }

  return guids;
}

function generateQrCodeImages(guids: string[]) {
  let canvas = document.createElement('canvas');
  const images: string[] = [];

  guids.forEach((guid) => {
    try {
      bwipjs.toCanvas(canvas, {
        bcid: 'qrcode',
        text: guid,
        scale: 3,
        backgroundcolor: 'ffffff',
      });

      images.push(canvas.toDataURL('image/jpeg'));
    } catch (e) {
      console.error('Error generating QrCode', e);
    }
  });

  return images;
}

function generateQrCodesPdf(quantity: number) {
  const guids = generateGuids(quantity);
  const images = generateQrCodeImages(guids);

  const pdf = new jsPDF();

  const width = 40;
  const height = 40;
  const numberOfImagesOnThePage = 24;
  const marginLeft = 10;
  const marginTop = 10;

  images.forEach((image, index) => {
    if (index > 0 && index % numberOfImagesOnThePage === 0) {
      pdf.addPage();
    }

    const indexOnAPage = index % numberOfImagesOnThePage;
    const x = marginLeft + (indexOnAPage % 4) * 50;
    const y = marginTop + Math.floor(indexOnAPage / 4) * 45;
    pdf.addImage(image, 'JPEG', x, y, width, height);
  });

  pdf.save(`stock-item-QRs-${dayjs(Date.now()).format('YYYY_MM_DD_HH_mm')}.pdf`);
}

const NUMBER_OF_QRS_PER_PAGE = 24;

function QrCodes() {
  const [form] = Form.useForm<{ quantity: number }>();
  const [quantity, setQuantity] = useState<number | undefined>(NUMBER_OF_QRS_PER_PAGE);

  const onFinish = async () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      generateQrCodesPdf(values.quantity);
    });
  };

  return (
    <>
      <div>
        <Text>Number of pages: {quantity ? Math.ceil(quantity / NUMBER_OF_QRS_PER_PAGE) : 0}</Text>
      </div>
      <br />
      <div>
        <Form form={form} requiredMark={false} initialValues={{ quantity: NUMBER_OF_QRS_PER_PAGE }} onFinish={onFinish}>
          <Form.Item
            label="Quantity"
            name="quantity"
            rules={[
              { required: true, message: 'Please input quantity' },
              {
                validator: (rule, value) => {
                  return (value > 240 || value < 1) && value ? Promise.reject() : Promise.resolve();
                },
                message: 'Please input number of QRs between 1-240',
              },
            ]}
          >
            <InputNumber
              autoFocus
              min={1}
              max={240}
              precision={0}
              onChange={(value) => {
                setQuantity(typeof value === 'string' ? parseInt(value, 10) : value ?? undefined);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" shape="round">
              <QrcodeOutlined />
              <span>Generate QR</span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default QrCodes;
