import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

type Props = {
  deleteItem: () => void;
  setClicked?: (visible: boolean) => void;
};

function DeleteButton({ deleteItem, setClicked }: Props) {
  const history = useHistory();

  return (
    <Popconfirm
      title="Are you sure you want to delete this tag?"
      placement="topLeft"
      cancelText="No"
      okText="Yes"
      onCancel={() => {
        const unblock = history.block();
        setTimeout(unblock, 400);
      }}
      onConfirm={deleteItem}
      onVisibleChange={(visible) => setClicked && setClicked(visible)}
    >
      <Button type="link" style={{ height: 'unset', padding: 0 }}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </Popconfirm>
  );
}

export default DeleteButton;
