import App from './App';
import AppProviders from './context/AppProviders';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import '../node_modules/primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeicons/primeicons.css';
import Axios from './api/axios-config';
import { MantineProvider } from '@mantine/core';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';

Axios.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Router>
    <AppProviders>
      <MantineProvider>
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
      </MantineProvider>
    </AppProviders>
  </Router>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
