import { useState, useCallback } from 'react';
import { TagTableOverview } from './TagsTableOverview';
import { TagForm } from './TagForm';
import useTags from 'hooks/useTags';
import { ProductTag } from 'models/product-tag.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

export type SelectableProductTag = ProductTag | undefined;

function Tags() {
  const [productTag, setProductTag] = useState<SelectableProductTag>(undefined);
  const { tags, isLoading, isError, add, update, remove } = useTags();

  const handleSaveProductTag = useCallback(
    async (data: ProductTag) => {
      if (data.id === 0) await add(data);
      else await update(data);

      setProductTag(undefined);
    },
    [add, update],
  );

  return (
    <div className="h-full grid">
      <div className="h-full col-6">
        <TagTableOverview items={tags} selectedItem={productTag} setSelectedItem={setProductTag} loading={isLoading} />
      </div>
      <div className="h-full col-6">
        {productTag ? (
          <TagForm
            productTag={productTag}
            onSave={handleSaveProductTag}
            onDelete={() => remove(productTag.id)}
            onCancel={() => setProductTag(undefined)}
          />
        ) : (
          <div className="h-full flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faTag} size="10x" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Tags;
