import React, { useEffect, useState } from 'react';
import { Switch, Table } from 'antd';
import { stringSorter } from '../../utils/sorters';
import Search from '../../components/Search';
import { useQuery } from 'react-query';
import { StockPointer } from '../../models/stock-pointer.model';

const columns = [
  {
    title: 'Product',
    key: 'product',
    dataIndex: ['productName'],
    width: '25%',
    sorter: (a: Pointer, b: Pointer) => stringSorter(a.productName, b.productName),
  },
  {
    title: 'Code',
    key: 'code',
    dataIndex: ['code'],
    width: '25%',
    sorter: (a: Pointer, b: Pointer) => stringSorter(a.code, b.code),
  },
  {
    title: 'Shop',
    key: 'shop',
    dataIndex: ['shopName'],
    width: '25%',
    sorter: (a: Pointer, b: Pointer) => stringSorter(a.shopName, b.shopName),
  },
  {
    title: 'Generic',
    key: 'generic',
    dataIndex: ['generic'],
    width: '25%',
    sorter: (a: Pointer, b: Pointer) => stringSorter(a.generic, b.generic),
  },
];

type Pointer = {
  id: number;
  productName: string;
  code: string;
  shopName: string;
  generic: string;
};

type Props = {
  setSelectedCode: (code: string) => void;
};

const queryUrl = (useOnlyAvailable: boolean) => `/stock-pointers?onlyAvailable=${useOnlyAvailable}`;

function StockPointerTable({ setSelectedCode }: Props) {
  const [useOnlyAvailable, setUseOnlyAvailable] = useState<boolean>(true);
  const { data, isLoading, isError } = useQuery<StockPointer[]>(queryUrl(useOnlyAvailable));
  const [stockPointers, setStockPointers] = useState<Pointer[]>([]);
  const [filteredStockPointers, setFilteredStockPointers] = useState(stockPointers);
  const [selectedId, setSelectedId] = useState<number>();

  useEffect(() => {
    if (!data) {
      return;
    }

    const pointers: Pointer[] = data
      .map((d) => {
        return {
          id: d.id,
          productName: d.product?.name ?? d.stockItem?.product?.name,
          code: d.code,
          shopName: d.shop?.name,
          generic: d.product ? 'yes' : 'no',
        };
      })
      .sort((a, b) => b.id - a.id);
    setStockPointers(pointers);
    setFilteredStockPointers(pointers);
  }, [data]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  const selectStockPointer = (stockPointer: Pointer) => {
    setSelectedCode(stockPointer.code);
    setSelectedId(stockPointer.id);
  };

  return (
    <>
      <Search
        autoFocus
        items={stockPointers}
        setItems={setFilteredStockPointers}
        columns={['productName', 'code', 'shopName']}
      />
      <br />
      <br />
      <Switch
        checked={useOnlyAvailable}
        onChange={() => setUseOnlyAvailable(!useOnlyAvailable)}
        style={{ marginRight: 5 }}
      />
      Only available
      <br />
      <br />
      <Table
        onRow={(stockItem) => ({ onClick: () => selectStockPointer(stockItem) })}
        rowSelection={{
          type: 'radio',
          onSelect: selectStockPointer,
          selectedRowKeys: selectedId ? [selectedId] : undefined,
        }}
        dataSource={filteredStockPointers}
        loading={isLoading}
        columns={columns}
        rowKey={(stockItem) => stockItem.id}
        scroll={{ y: 600 }}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
          position: ['bottomCenter'],
        }}
      />
    </>
  );
}

export default StockPointerTable;
