import { Button } from 'primereact/button';

interface Props {
  onAddClick: () => void;
}

export function TableHeader({ onAddClick }: Props) {
  return (
    <div className="flex justify-content-between align-items-center">
      <div>Tags</div>
      <Button size="small" label="Add" icon="pi pi-plus" onClick={onAddClick} />
    </div>
  );
}
