import { useQuery } from 'react-query';
import { Supplier } from '../models/supplier.model';

function useSuppliers() {
  const { data: suppliers, isLoading, isError } = useQuery<Supplier[]>(`/supplier`);

  return { suppliers, isLoading, isError };
}

export default useSuppliers;
