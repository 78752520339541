import { useCallback } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../context/AuthContext';
import { useHistory } from 'react-router';
import Logo from '../images/SmartShopping_logo_horizontal_color.svg';

function TopNav() {
  const history = useHistory();
  const { logout } = useAuth();

  const handleLogout = useCallback(() => {
    logout();
    history.push('/');
  }, [history, logout]);

  return (
    <div className="flex h-full justify-content-between align-items-center pl-2 pr-4">
      <img className="p-1" style={{ width: '200px' }} src={Logo} alt="React Logo" />
      <Button size="small" label="Logout" icon="pi pi-power-off" onClick={handleLogout} />
    </div>
  );
}

export default TopNav;
