// discount types
export const PERCENT_DISCOUNT_TYPE = 'percentage';
export const EXPLICIT_DISCOUNT_TYPE = 'explicit';
export const DIRECT_DISCOUNT_TYPE = 'direct';

// rule types
export const DATE_RANGE_RULE_TYPE = 'dateRange';
export const EXPIRY_RULE_TYPE = 'expiry';

// rule applied by
export const TAG = 'tag';
export const PRODUCT = 'product';

// rule groups
export const EXPIRY_DATE_TAGS = 'expiry-date-tags';
export const EXPIRY_DATE_PRODUCTS = 'expiry-date-products';
export const DATE_RANGE_TAGS = 'date-range-tags';
export const DATE_RANGE_PRODUCTS = 'date-range-products';

// roles
export const ROLE_CUSTOMER = 'Customer';
export const ROLE_SHOP_ASSISTANT = 'ShopAssistant';
