import axios from 'axios';
import { AddProductTag, ProductTag } from '../models/product-tag.model';

function getAll() {
  return axios.get<ProductTag[]>('/tags/all');
}

function post(tag: AddProductTag) {
  return axios.post<ProductTag>('/tags', tag);
}

function put(tag: ProductTag) {
  return axios.put<ProductTag>('/tags', tag);
}

function remove(tagKey: number) {
  return axios.delete(`/tags/${tagKey}`);
}

const TagsApi = {
  getAll,
  post,
  put,
  remove,
};

export default TagsApi;
