import React, { useEffect, useState } from 'react';
import ShopForm from './ShopForm';
import useShops from '../../hooks/useShops';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import QrCode from '../../components/QrCode';
import { Shop } from '../../models/shop.model';
import { stringSorter } from '../../utils/sorters';

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    width: '40%',
    sorter: (a: Shop, b: Shop) => stringSorter(a.name, b.name),
  },
  {
    title: 'Tax number',
    key: 'taxNumber',
    dataIndex: 'taxNumber',
    width: '40%',
    sorter: (a: Shop, b: Shop) => stringSorter(a.name, b.name),
  },
  {
    title: 'Street',
    key: 'street',
    dataIndex: 'street',
    width: '40%',
    sorter: (a: Shop, b: Shop) => stringSorter(a.name, b.name),
  },
];

function Shops() {
  const { shops, isLoading, isError, add, edit, synchronize } = useShops();
  const [selectedShop, setSelectedShop] = useState<Shop>();
  const selectShop = (shop: Shop) => setSelectedShop(shop);

  useEffect(() => {
    synchronize();
  }, [synchronize]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <Row gutter={32}>
      <Col md={8}>
        <Button onClick={() => setSelectedShop(undefined)}>
          <PlusOutlined /> New
        </Button>
        <br />
        <br />
        <ShopForm selectedShop={selectedShop} addShop={add} editShop={edit} />
      </Col>
      <Col md={12}>
        <Table
          columns={columns}
          dataSource={shops}
          loading={isLoading}
          scroll={{ y: 700 }}
          pagination={{
            showSizeChanger: false,
            pageSize: 15,
            className: 'pagination',
          }}
          onRow={(shop) => ({ onClick: () => selectShop(shop) })}
          rowSelection={{
            type: 'radio',
            onSelect: selectShop,
            selectedRowKeys: selectedShop ? [selectedShop?.qrCode] : [],
          }}
          rowKey={(shop) => shop.qrCode}
        />
      </Col>
      <Col md={4}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {selectedShop ? <QrCode text={selectedShop.qrCode} style={{ width: 160, height: 160 }} /> : null}
        </div>
      </Col>
    </Row>
  );
}

export default Shops;
