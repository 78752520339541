import { notification } from 'antd';

function notifyError(status: number) {
  notification.error({
    message: `Something went wrong`,
    description: `Status code ${status}`,
  });
}

export { notifyError };
