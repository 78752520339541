import React, { useState } from 'react';
import { Button } from 'antd';
import QrCode from './QrCode';
import { v4 as uuid } from 'uuid';

const generateGuid = () => `SmartS.${uuid()}`;

function GenerateQrCode() {
  const [guid, setGuid] = useState(() => generateGuid());

  return (
    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
      <QrCode text={guid} style={{ padding: 10 }} />
      <Button onClick={() => setGuid(generateGuid())}>Generate</Button>
    </div>
  );
}

export default GenerateQrCode;
