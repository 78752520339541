import React, { useEffect, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { Row, Col, Table, Button } from 'antd';
import Search from '../../components/Search';
import { numberSorter, stringSorter } from '../../utils/sorters';
import { exportStockItemsReport } from './exportToXlsx';
import { useQuery } from 'react-query';
import { StockItemsReportItem } from '../../models/stock-items-report-item.model';
import { v4 as uuid } from 'uuid';

const columns = [
  {
    title: 'Shop',
    key: 'shopName',
    dataIndex: 'shopName',
    width: '30%',
    sorter: (a: StockItemsReportItem, b: StockItemsReportItem) => stringSorter(a.shopName, b.shopName),
  },
  {
    title: 'Product',
    key: 'productName',
    dataIndex: 'productName',
    width: '30%',
    sorter: (a: StockItemsReportItem, b: StockItemsReportItem) => stringSorter(a.productName, b.productName),
  },
  {
    title: 'Quantity left',
    key: 'quantity',
    dataIndex: 'quantity',
    width: '10%',
    sorter: (a: StockItemsReportItem, b: StockItemsReportItem) => numberSorter(a.quantity, b.quantity),
  },
];

function StockItemsReport() {
  const { data: stockItemsReport, isLoading, isError } = useQuery<StockItemsReportItem[]>('/stock-items-report');
  const [filteredStockItems, setFilteredStockItems] = useState(stockItemsReport);

  useEffect(() => {
    setFilteredStockItems(stockItemsReport);
  }, [stockItemsReport]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <Row justify="start" gutter={16}>
        <Col>
          <Search
            autoFocus
            items={stockItemsReport ?? []}
            setItems={setFilteredStockItems}
            columns={['shopName', 'productName']}
          />
        </Col>
        <Col>
          <Button type="default" onClick={() => exportStockItemsReport(stockItemsReport ?? [])}>
            <ExportOutlined /> Export
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Table
        dataSource={!isLoading ? filteredStockItems : []}
        loading={isLoading}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
        }}
        scroll={{ y: 650 }}
        rowKey={() => uuid()}
      />
    </div>
  );
}

export default StockItemsReport;
