import { useCallback, useEffect, useState } from 'react';
import { notifyError } from '../utils/notify';
import ShopsApi from '../api/shopsApi';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { AddShop, EditShop, Shop } from '../models/shop.model';
import { RestError } from '../models/rest-error.model';

const addShop = async (shop: AddShop) => {
  const { data } = await ShopsApi.post(shop);
  return data;
};

const editShop = async (shop: EditShop) => {
  const { data } = await ShopsApi.put(shop);
  return data;
};

const queryKey = `/shop`;

function useShops() {
  const queryCache = useQueryCache();
  const { data, isLoading, isError } = useQuery<Shop[]>(queryKey);
  const [shops, setShops] = useState(data ?? []);

  const [add] = useMutation<Shop, RestError, AddShop, Shop[]>(addShop, {
    onMutate: (newShop) => {
      // newShop has to be casted to Shop type to make typescript accept it in array
      const x = newShop as Shop;
      queryCache.cancelQueries(queryKey);
      const previousTags = queryCache.getQueryData<Shop[]>(queryKey);
      queryCache.setQueryData<Shop[]>(queryKey, (old) => {
        if (old) {
          return [{ ...x, id: old[0].id + 1 }, ...old];
        }

        return [{ ...x, id: 0 }];
      });

      return previousTags!;
    },
    onError: (error, newShop, previousShops) => {
      notifyError(error.statusCode);
      queryCache.setQueryData<Shop[]>(queryKey, previousShops);
    },
    onSettled: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const [edit] = useMutation<Shop, RestError, EditShop, Shop[]>(editShop, {
    onMutate: (editedShop) => {
      queryCache.cancelQueries(queryKey);
      const previousTags = queryCache.getQueryData<Shop[]>(queryKey);
      queryCache.setQueryData<Shop[]>(queryKey, (old) => {
        return old!.map((prevShop) => {
          if (prevShop.id === editedShop.id) {
            return { ...prevShop, ...editedShop };
          }
          return prevShop;
        });
      });

      return previousTags!;
    },
    onError: (error, editedShop, previousShops) => {
      notifyError(error.statusCode);
      queryCache.setQueryData(queryKey, previousShops);
    },
    onSettled: () => {
      queryCache.invalidateQueries(queryKey);
    },
  });

  const synchronize = useCallback(() => {
    ShopsApi.synchronize()
      .then(({ data }) => {
        setShops(sort(data));
      })
      .catch((error) => {
        notifyError(error.statusCode);
      });
  }, []);

  useEffect(() => {
    if (data) {
      setShops(sort(data));
    }
  }, [data]);

  function sort(shops: Shop[]): Shop[] {
    return shops.sort((a, b) => b.id - a.id);
  }

  return { shops, isLoading, isError, add, edit, synchronize };
}

export default useShops;
