import { useState } from 'react';
import { Button, Col, DatePicker, notification, Row, Spin } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { match, useHistory, useLocation, useRouteMatch } from 'react-router';
import { History } from 'history';
import SalesApi from '../../api/salesApi';
import dayjs from 'dayjs';

const onChange = (dateString: string[], match: match, history: History) => {
  history.push({ pathname: match.path, search: `?from=${dateString[0]}&to=${dateString[1]}` });
};

function DeliveryReport() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get('from');
  const to = params.get('to');
  const [pendingRequest, setPendingRequest] = useState(false);

  if (!location.search) {
    history.push({
      pathname: match.path,
      search: `?from=${dayjs().add(-6, 'day').startOf('day').format('YYYY-MM-DD')}&to=${dayjs()
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DD')}`,
    });
  }

  function fetchReport() {
    setPendingRequest(true);
    SalesApi.getDeliveryReport(dayjs(from).utc(true).format(), dayjs(to).utc(true).format())
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `delivery-report_${dayjs(from).format('DD-MM-YYYY')}-${dayjs(to).format('DD-MM-YYYY')}.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        notification.error({ message: 'Something went wrong' });
      })
      .finally(() => {
        setPendingRequest(false);
      });
  }

  return (
    <>
      <Row justify="start" gutter={16}>
        <Col>
          <DatePicker.RangePicker
            onChange={(_, dateString) => onChange(dateString, match, history)}
            defaultValue={
              location.search
                ? [dayjs(from), dayjs(to)]
                : [dayjs().add(-6, 'day').startOf('day'), dayjs().startOf('day')]
            }
            allowClear={false}
          />
        </Col>
        <Col>
          <Button type="default" onClick={fetchReport}>
            <ExportOutlined /> Export
          </Button>
          {pendingRequest ? <Spin style={{ marginLeft: '16px' }} /> : null}
        </Col>
      </Row>
    </>
  );
}

export default DeliveryReport;
