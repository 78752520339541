import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { stringSorter } from '../../utils/sorters';
import { useQuery } from 'react-query';
import { StockItem } from '../../models/stock-item.model';
import Search from 'components/Search';

const columns = [
  {
    title: 'Product',
    key: 'product',
    dataIndex: ['product', 'name'],
    width: '25%',
    sorter: (a: StockItem, b: StockItem) => stringSorter(a.product.name, b.product.name),
  },
  {
    title: 'Barcode',
    key: 'barcode',
    dataIndex: ['product', 'barcode'],
    width: '25%',
    sorter: (a: StockItem, b: StockItem) => stringSorter(a.product.barcode ?? '', b.product.barcode ?? ''),
  },
  {
    title: 'Shop',
    key: 'shop',
    dataIndex: ['shop', 'name'],
    width: '25%',
    sorter: (a: StockItem, b: StockItem) => stringSorter(a.shop.name, b.shop.name),
  },
];

type Props = {
  selectedId?: number;
  setSelectedId: (selectedId: number) => void;
};

function StockItemTable({ selectedId, setSelectedId }: Props) {
  const { data, isLoading, isError } = useQuery<StockItem[]>('/stock-items');
  const [stockItems, setStockItems] = useState(data);

  useEffect(() => {
    setStockItems(data);
  }, [data]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  const selectStockItem = (stockItem: StockItem) => setSelectedId(stockItem.id);

  return (
    <>
      <Search autoFocus items={data ?? []} setItems={setStockItems} columns={['product.name', 'product.barcode']} />
      <br />
      <br />
      <Table
        onRow={(stockItem) => ({ onClick: () => selectStockItem(stockItem) })}
        rowSelection={{
          type: 'radio',
          onSelect: selectStockItem,
          selectedRowKeys: selectedId ? [selectedId] : undefined,
        }}
        dataSource={stockItems}
        loading={isLoading}
        columns={columns}
        rowKey={(stockItem) => stockItem.id}
        scroll={{ y: 650 }}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
          className: 'pagination',
          position: ['bottomCenter'],
        }}
      />
    </>
  );
}

export default StockItemTable;
