import { useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { Product } from 'models/product.model';
import { History } from 'history';
import generic_image from '../../images/generic_product_image.png';
import DeleteButton from 'components/DeleteButton';

interface Props {
  product: Product;
  deleteProduct: (id: string, history: History) => void;
  hoverable?: boolean;
}

export function ProductElement({ product, deleteProduct, hoverable = true }: Props) {
  const { name, defaultPrice } = product;
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const history = useHistory();
  const { data } = useQuery<string>(`/products/image/${product?.imageHandle}`, {
    refetchOnWindowFocus: false,
  });

  const deleteItem = () => deleteProduct(product.id, history);

  return (
    <div
      className="w-22rem h-10rem border-round-lg shadow-3 relative cursor-pointer"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        history.push(`/products/edit/${product.id}`);
      }}
    >
      {(hovered || clicked) && (
        <div
          className="absolute border-round-right-lg px-1 py-2 bg-white shadow-3"
          style={{
            top: '8px',
            right: '-23px',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <DeleteButton deleteItem={deleteItem} setClicked={setClicked} />
        </div>
      )}
      <div className="flex gap-2 justify-content-between py-2 px-4 h-full">
        <div className="h-full flex align-items-center">
          <img
            src={data ?? generic_image}
            alt=""
            style={{ width: 120, height: 120, padding: 5, position: 'relative', objectFit: 'contain' }}
          />
        </div>
        <div className="text-700 py-2 flex flex-column justify-content-between align-items-end">
          <div className="text-xl font-bold" style={{ color: 'black' }}>
            {name}
          </div>
          <div style={{ color: 'black' }}>{defaultPrice}€</div>
        </div>
      </div>
    </div>
  );
}
