import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import ProductList from './ProductList';
import ProductAdd from './ProductAdd';
import ProductEdit from './ProductEdit';
import useProducts from '../../hooks/useProducts';

function Products() {
  const match = useRouteMatch();
  const { products, isLoading, isError, addProduct, editProduct, deleteProduct } = useProducts();

  return (
    <Switch>
      <Route path={`${match.url}/add`}>
        <ProductAdd products={products} addProduct={addProduct} isLoading={isLoading} />
      </Route>
      <Route path={`${match.url}/edit/:id`}>
        <ProductEdit products={products} editProduct={editProduct} isLoading={isLoading} />
      </Route>
      <Route path={match.url}>
        <ProductList products={products} isLoading={isLoading} isError={isError} deleteProduct={deleteProduct} />
      </Route>
      <Redirect to="/error/404" />
    </Switch>
  );
}

export default Products;
