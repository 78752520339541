import { ProductTag } from 'models/product-tag.model';
import FormikInputText from 'components/Formik/FormikInputText';
import FormikColorPicker from 'components/Formik/FormikColorPicker';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormHeader } from 'components/FormHeader';

interface Props {
  productTag: ProductTag;
  onSave: (form: ProductTag) => Promise<any>;
  onCancel: () => void;
  onDelete: () => Promise<any> | void;
}

export function TagForm({ productTag, onSave, onCancel, onDelete }: Props) {
  const initialValues: ProductTag = {
    id: productTag.id ?? 0,
    name: productTag.name ?? '',
    color: productTag.color ?? '#06B6D4',
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Must be 3 characters or more'),
    color: Yup.string().required('Required'),
  });

  return (
    <div className="h-full p-2">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form>
            <FormHeader
              header={!!productTag.name ? productTag.name : 'Add new tag'}
              showDeleteButton={!!productTag.id}
              saveButtonDisabled={!formik.dirty || !formik.isValid}
              onSaveClick={() => formik.submitForm()}
              onCancelClick={onCancel}
              onDeleteClick={onDelete}
            />
            <div className="pt-2 flex flex-column gap-2">
              <div>
                <FormikInputText label="Name" name="name" validationSchema={validationSchema} isIndependent />
              </div>
              <div>
                <FormikColorPicker label="Color" name="color" validationSchema={validationSchema} isIndependent />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
