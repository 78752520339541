import { useMemo } from 'react';
import { ColorPicker, ColorPickerProps } from 'primereact/colorpicker';
import { Field, FastField, FormikContextType, FieldInputProps, FieldMetaProps } from 'formik';
import { FormInputLabel } from './FormInputLabel';
import { ErrorMessage } from 'formik';
import TextError from './TextError';
import * as Yup from 'yup';

interface FormikColorPickerProps extends ColorPickerProps {
  label: string;
  name: string;
  isIndependent?: boolean;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, ''>;
}

function FormikColorPicker({ label, name, isIndependent, validationSchema, ...rest }: FormikColorPickerProps) {
  const Component = useMemo(() => (isIndependent ? FastField : Field), [isIndependent]);

  return (
    <>
      <FormInputLabel nameFor={name} validationSchema={validationSchema}>
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<string>;
          meta: FieldMetaProps<string>;
        }) => (
          <div className="p-1">
            <ColorPicker
              id={name}
              inputStyle={{ width: '5rem' }}
              onBlur={(e) => form.setFieldTouched(name, true)}
              onChange={(e) => form.setFieldValue(name, e.value)}
              value={field.value}
              {...rest}
            />
          </div>
        )}
      </Component>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export default FormikColorPicker;
