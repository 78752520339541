import axios from 'axios';
import { Product } from '../models/product.model';

function get(id: number) {
  return axios.get<Product>(`/products/${id}`);
}

function getAll() {
  return axios.get<Product[]>('/products');
}

function getImage(handle: string) {
  return axios.get<string>(`/products/image/${handle}`);
}

function post(product: Product) {
  return axios.post<Product>('/products', product);
}

function put(product: Product) {
  return axios.put('/products', product);
}

function remove(id: string) {
  return axios.delete(`/products/${id}`);
}

const ProductApi = {
  get,
  getAll,
  getImage,
  post,
  put,
  remove,
};

export default ProductApi;
