import axios from 'axios';
import { AddShop, EditShop, Shop } from '../models/shop.model';
import { SynchronizeShop } from '../models/synchronize-shop.model';

function get(id: number) {
  return axios.get<Shop>(`/shops/${id}`);
}

function getAll() {
  return axios.get('/shops');
}

function post(shop: AddShop) {
  return axios.post<Shop>('/shops', shop);
}

function put(shop: EditShop) {
  return axios.put<Shop>('/shops', shop);
}

function remove(shopKey: number) {
  return axios.delete(`/shops/${shopKey}`);
}

function synchronize() {
  const data: SynchronizeShop = {};
  return axios.post<Shop[]>('/shops/synchronize-shops', data);
}

const ShopsApi = {
  get,
  getAll,
  post,
  put,
  remove,
  synchronize,
};

export default ShopsApi;
