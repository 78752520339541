import React from 'react';
import { Layout } from 'antd';
import TopNav from './components/TopNav';
import SideNav from './components/SideNav';
import { Redirect, Route, Switch } from 'react-router';
import QrCodes from './views/QrCodes';
import useCollapse from './hooks/useCollapse';
import Tags from 'views/Tags/Tags';
import Products from './views/Products/Products';
import Error404 from './views/Errors/Error404';
import StockItems from './views/StockItems/StockItems';
import SalesReport from './views/Reports/SalesReport';
import BatchReport from './views/Reports/BatchReport';
import Shops from './views/Shops/Shops';
import StockPointers from './views/StockPointers/StockPointers';
import StockItemsReport from 'views/Reports/StockItemsReport';
import TicketReport from './views/Reports/TicketReport';
import Translations from './views/Translations/Translations';
import DeliveryReport from './views/Reports/DeliveryReport';

const { Header, Content, Sider } = Layout;

function Routes() {
  return (
    <Switch>
      <Route path="/products" component={Products} />
      <Route path="/qr-codes" component={QrCodes} exact />
      <Route path="/tags" component={Tags} exact />
      <Route path="/stock-items" component={StockItems} />
      <Route path="/stock-pointers" component={StockPointers} />
      <Route path="/shops" component={Shops} />
      <Route path="/translations" component={Translations} />
      <Route path="/sales-report" component={SalesReport} />
      <Route path="/batch-report" component={BatchReport} />
      <Route path="/stock-items-report" component={StockItemsReport} />
      <Route path="/ticket-report" component={TicketReport} />
      <Route path="/delivery-report" component={DeliveryReport} />
      <Route path="/error/404" component={Error404} />
      <Redirect from="/" to="/products" exact />
      <Redirect to="/error/404" />
    </Switch>
  );
}

function AuthenticatedApp() {
  const { collapsed, setCollapsed } = useCollapse();

  return (
    <>
      <Layout style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <Header
          style={{
            boxShadow: '0 2px 8px #f0f1f2',
            height: '65px',
            background: '#fff',
            width: '100%',
            zIndex: 10,
            padding: 0,
            position: 'fixed',
          }}
        >
          <TopNav />
        </Header>
        <div className="flex w-full">
          <Sider
            style={{ position: 'fixed', height: 'calc(100% - 65px)', marginTop: '65px' }}
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            width={200}
            theme="light"
          >
            <SideNav collapsed={collapsed} />
          </Sider>
          <Layout
            style={{
              marginLeft: collapsed ? '80px' : '200px',
              marginTop: '65px',
              height: 'calc(100% - 65px)',
              width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)',
              position: 'fixed',
            }}
          >
            <Content style={{ margin: 0, background: '#fff', height: 'calc(100% - 65px)' }}>
              <Routes />
            </Content>
          </Layout>
        </div>
      </Layout>
    </>
  );
}

export default AuthenticatedApp;
